<template>
  <div class="csn-mc">
    <div class="csn-mc-input">
      <button
        :disabled="isDecrimentDisabled"
        @click="decriment"
        class="btn csn-mc-decrement"
      >
        -
      </button>
      <div class="csn-mc-value">{{ renderedValue }}</div>
      <button
        :disabled="isIncrimentDisabled"
        @click="increment"
        class="btn csn-mc-increment"
      >
        +
      </button>
    </div>
    <div class="csn-mc-limit">{{ limit }}</div>
  </div>
</template>
<script>
import { Currency, MONEY_COUNTER } from '@/constants'
import { formatNumberLocale, formatNumberLocale as format } from '@/helpers'
import { inputMixin } from '@/mixins'

export default {
  name: MONEY_COUNTER,
  mixins: [inputMixin],
  props: {
    currency: {
      type: String,
      default: Currency.EUR,
    },
    min: Number,
    max: Number,
  },
  computed: {
    t() {
      return this.$createComponentTranslator(MONEY_COUNTER)
    },
    renderedValue() {
      return formatNumberLocale({
        currency: this.currency,
        number: Number(this.innerValue),
        needsCoins: false,
      })
    },
    limit() {
      const min = format({ number: this.min, needsCoins: false })
      const max = format({ number: this.max, needsCoins: false })

      return `${this.t('min')}: ${min} / ${this.t('max')}: ${max}`
    },
    isDecrimentDisabled() {
      return this.innerValue <= this.min
    },
    isIncrimentDisabled() {
      return this.innerValue >= this.max
    },
  },
  methods: {
    increment() {
      this.handleInput(Number(this.innerValue) + 1)
    },
    decriment() {
      this.handleInput(Number(this.innerValue) - 1)
    },
  },
}
</script>
